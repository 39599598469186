import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
    const [role, setRole] = React.useState(localStorage.getItem('role'))

    return (
        <div>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className={window.location.pathname === '/dashboard' ? 'nav-item active' : "nav-item"}>
                        <Link to="/dashboard" className="nav-link">
                            <i className="mdi mdi-home menu-icon" />
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>
                    {
                        role === 'Issuer' ?
                            <li className={window.location.pathname === '/issuer' ? 'nav-item active' : "nav-item"}>
                                <Link to="/issuer" className="nav-link">
                                    <i className="mdi mdi-account-card-details menu-icon" />
                                    <span className="menu-title">Issue Credentials</span>
                                </Link>
                            </li>
                            : null
                    }
                    {
                        role === 'Holder' ?
                            <li className={window.location.pathname === '/holder' ? 'nav-item active' : "nav-item"}>
                                <Link to="/holder" className="nav-link">
                                    <i className="mdi mdi-account-card-details menu-icon" />
                                    <span className="menu-title">Issued Credentials</span>
                                </Link>
                            </li>
                            : null
                    }
                    {
                        role === 'Verifier' ?
                            <li className={window.location.pathname === '/verifier' ? 'nav-item active' : "nav-item"}>
                                <Link to="/verifier" className="nav-link">
                                    <i className="mdi mdi-account-card-details menu-icon" />
                                    <span className="menu-title">Verify Credentials</span>
                                </Link>
                            </li>
                            : null
                    }
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar
