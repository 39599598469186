import { LineChart } from '../components/LineChart'
import { holderInstance, verifierInstance } from '../Axios/Auth'
import { issuerInstance } from '../Axios/Auth'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import React from 'react'

const Dashboard = () => {
    const [createdAt, setCreatedAt] = React.useState('')
    const [DID, setDID] = React.useState('')

    const [monthWiseRequests, setMonthWiseRequests] = React.useState({})
    const [totalRequests, setTotalRequests] = React.useState(0)
    const [acceptedRequests, setAcceptedRequests] = React.useState(0)

    const [issuedSchemas, setIssuedSchemas] = React.useState(0)

    const [credentials, setCredentials] = React.useState([])
    const [issueRequests, setIssueRequests] = React.useState(0)
    const [verifyRequests, setVerifyRequests] = React.useState(0)

    const [pendingRequests, setPendingRequests] = React.useState(0)

    React.useEffect(() => {
        if (localStorage.getItem("token") !== null && localStorage.getItem('role') === 'Issuer') {
            issuerInstance.get('/getDID').then((response) => {
                setDID(response.data.did)
            })
            issuerInstance.get('/profile').then((response) => {
                setCreatedAt(
                    new Date(response.data.createdAt).toLocaleString('default', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })
                )
            })
            issuerInstance.post('/getSchemas', {
                email: localStorage.getItem('email')
            }).then((response) => {
                setIssuedSchemas(response.data.length)
            })
            issuerInstance.get('/credentialsList').then((response) => {
                setTotalRequests(response.data.length)
                setAcceptedRequests(response.data.filter((credential) => credential.status === 'issued').length)
                const monthWiseRequests = {}
                response.data.forEach((credential) => {
                    const date = new Date(credential.createdAt)
                    const month = date.toLocaleString('default', { month: 'long' })
                    if (month in monthWiseRequests) {
                        monthWiseRequests[month] += 1
                    }
                    else {
                        monthWiseRequests[month] = 1
                    }
                })
                setMonthWiseRequests(monthWiseRequests)
            })
        }
        else if (localStorage.getItem("token") !== null && localStorage.getItem('role') === 'Verifier') {
            verifierInstance.get('/getDID').then((response) => {
                setDID(response.data.did)
            })
            verifierInstance.get('/profile').then((response) => {
                setCreatedAt(
                    new Date(response.data.createdAt).toLocaleString('default', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })
                )
            })
            verifierInstance.get('/').then((response) => {
                setTotalRequests(response.data.verificationRequestsData.length)
                setPendingRequests(response.data.verificationRequestsData.filter((credential) => credential.status === 'pending').length)
                setAcceptedRequests(response.data.verificationRequestsData.filter((request) => request.status === 'accepted').length)
                const monthWiseRequests = {}
                response.data.verificationRequestsData.forEach((request) => {
                    const date = new Date(request.createdAt)
                    const month = date.toLocaleString('default', { month: 'long' })
                    if (month in monthWiseRequests) {
                        monthWiseRequests[month] += 1
                    }
                    else {
                        monthWiseRequests[month] = 1
                    }
                })
                setMonthWiseRequests(monthWiseRequests)
            })
        }
        else if (localStorage.getItem("token") !== null && localStorage.getItem('role') === 'Holder') {
            holderInstance.get('/getDID').then((response) => {
                setDID(response.data.did)
            })
            holderInstance.get('/profile').then((response) => {
                setCreatedAt(
                    new Date(response.data.createdAt).toLocaleString('default', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })
                )
            })
            holderInstance.get('/getNotificationsStats').then((response) => {
                setIssueRequests(response.data.issueNotifications.length)
                setVerifyRequests(response.data.verifyNotifications.length)
                const monthWiseRequests = {}
                response.data.issueNotifications.forEach((request) => {
                    const date = new Date(request.createdAt)
                    const month = date.toLocaleString('default', { month: 'long' })
                    if (month in monthWiseRequests) {
                        monthWiseRequests[month] += 1
                    }
                    else {
                        monthWiseRequests[month] = 1
                    }
                })
                response.data.verifyNotifications.forEach((request) => {
                    const date = new Date(request.createdAt)
                    const month = date.toLocaleString('default', { month: 'long' })
                    if (month in monthWiseRequests) {
                        monthWiseRequests[month] += 1
                    }
                    else {
                        monthWiseRequests[month] = 1
                    }
                })
                setMonthWiseRequests(monthWiseRequests)
            })
            holderInstance.get('/').then(response => {
                setCredentials(response.data.length)
            })
        }
        else {
            window.location.href = '/login'
        }
    }, [])
    return (
        <>
            <div className="container-scroller">
                {/* partial:partials/_navbar.html */}
                <Navbar />
                {/* partial */}
                <div className="container-fluid page-body-wrapper">
                    {/* partial:partials/_sidebar.html */}
                    <Sidebar />
                    {/* partial */}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body dashboard-tabs p-0">
                                            <ul className="nav nav-tabs px-4" role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="overview-tab"
                                                        data-bs-toggle="tab"
                                                        href="#overview"
                                                        role="tab"
                                                        aria-controls="overview"
                                                        aria-selected="true"
                                                    >
                                                        Overview
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content py-0 px-0">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="overview"
                                                    role="tabpanel"
                                                    aria-labelledby="overview-tab"
                                                >
                                                    <div className="d-flex flex-wrap justify-content-xl-between">
                                                        <div className="d-none d-xl-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                            <i className="mdi mdi-calendar-heart icon-lg me-3 text-primary" />
                                                            <div className="d-flex flex-column justify-content-around">
                                                                <small className="mb-1 text-muted">
                                                                    Start date
                                                                </small>
                                                                <div className="dropdown">
                                                                    <div
                                                                        className="btn btn-secondary p-0 bg-transparent border-0 text-dark shadow-none font-weight-medium"
                                                                    >
                                                                        <h5 className="mb-0 d-inline-block">
                                                                            {createdAt}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            localStorage.getItem('role') === 'Issuer' ?
                                                                <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                    <i className="mdi mdi-eye me-3 icon-lg text-danger" />
                                                                    <div className="d-flex flex-column justify-content-around">
                                                                        <small className="mb-1 text-muted">Schemas</small>
                                                                        <h5 className="me-2 mb-0">{issuedSchemas}</h5>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            localStorage.getItem('role') !== 'Holder' ?
                                                                <>
                                                                    <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                        <i className="mdi mdi-arrow-up-bold me-3 icon-lg text-warning" />
                                                                        <div className="d-flex flex-column justify-content-around">
                                                                            <small className="mb-1 text-muted">
                                                                                Total requests
                                                                            </small>
                                                                            <h5 className="me-2 mb-0">{totalRequests}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                        <i className="mdi mdi-checkbox-multiple-marked me-3 icon-lg text-success" />
                                                                        <div className="d-flex flex-column justify-content-around">
                                                                            <small className="mb-1 text-muted">Accepted requests</small>
                                                                            <h5 className="me-2 mb-0">{acceptedRequests}</h5>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                        <i className="mdi mdi-eye me-3 icon-lg text-danger" />
                                                                        <div className="d-flex flex-column justify-content-around">
                                                                            <small className="mb-1 text-muted">Credentials</small>
                                                                            <h5 className="me-2 mb-0">{credentials}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                        <i className="mdi mdi-arrow-up-bold me-3 icon-lg text-warning" />
                                                                        <div className="d-flex flex-column justify-content-around">
                                                                            <small className="mb-1 text-muted">
                                                                                Issue requests
                                                                            </small>
                                                                            <h5 className="me-2 mb-0">{issueRequests}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                        <i className="mdi mdi-checkbox-multiple-marked me-3 icon-lg text-success" />
                                                                        <div className="d-flex flex-column justify-content-around">
                                                                            <small className="mb-1 text-muted">Verify requests</small>
                                                                            <h5 className="me-2 mb-0">{verifyRequests}</h5>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                        {
                                                            localStorage.getItem('role') === 'Verifier' ?
                                                                <div className="d-flex border-md-right flex-grow-1 align-items-center p-3 item">
                                                                    <i className="mdi mdi-av-timer me-3 icon-lg text-danger" />
                                                                    <div className="d-flex flex-column justify-content-around">
                                                                        <small className="mb-1 text-muted">Pending requests</small>
                                                                        <h5 className="me-2 mb-0">{pendingRequests}</h5>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">Monthly Requests</p>
                                            <p className="mb-4">
                                                Track your requests month by month, along with your progress and gain insights.
                                            </p>
                                            <div
                                                id="cash-deposits-chart-legend"
                                                className="d-flex pt-3"
                                            />
                                            <LineChart data={monthWiseRequests} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">
                                                {
                                                    localStorage.getItem('role') === 'Holder' ?
                                                        'Holder DID'
                                                        :
                                                        localStorage.getItem('role') === 'Verifier' ?
                                                            'Verifier DID'
                                                            :
                                                            localStorage.getItem('role') === 'Issuer' ?
                                                                'Issuer DID'
                                                                :
                                                                'DID'
                                                }
                                            </p>
                                            <h5>did:sov:{DID}</h5>
                                            <p className="text-muted">
                                                This is your unique identity over the internet. You can use this to take control of your
                                                personal information and choose how it is shared and used.
                                            </p>
                                            <div id="total-sales-chart-legend" />
                                        </div>
                                        <img src='ChartBg.png' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content-wrapper ends */}
                    </div>
                    {/* main-panel ends */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            {/* container-scroller */}
        </>
    )
}

export default Dashboard
