import { verifierInstance } from '../Axios/Auth'
import { toastError } from './Alerts'
import React from 'react'

const VerificationRequestDetailsModal = (props) => {

    const [attributes, setAttributes] = React.useState({})

    React.useEffect(() => {
        verifierInstance.post('/verifyDetails', { proofRecordId: props.request.proofRecordId })
            .then(res => {
                if (res.data.message === "Verification Failed") {
                    toastError("Verification Failed")
                    props.closeModal(null)
                }
                setAttributes(res.data.attributes)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div>
            <div style={{ zIndex: 99 }} className="modal__backdrop">
                <div className="modal__container">
                    <div className="modal__close position-relative">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => { props.closeModal(null) }}
                        ></button>
                    </div>
                    <h1>Details</h1>
                    <div>
                        {
                            Object.keys(attributes).map((key, index) => {
                                return (
                                    <div class="mb-3">
                                        <strong><label for="exampleInputEmail1" class="form-label">{key}</label></strong>
                                        <input class="form-control" value={attributes[key]} disabled />
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerificationRequestDetailsModal
