import NotificationModal from '../components/NotificationModal'
import { holderInstance } from '../Axios/Auth'
import React from 'react'

const Navbar = (props) => {
    const [sidebarActiveOnMobile, setSidebarActiveOnMobile] = React.useState(false)
    const [sidebarActiveOnPC, setSidebarActiveOnPC] = React.useState(false)

    const [notificationModal, setNotificationModal] = React.useState(false)
    const [notifications, setNotifications] = React.useState([])
    const [notification, setNotification] = React.useState({})

    React.useEffect(() => {
        if (localStorage.getItem('role') === "Holder") {
            holderInstance.get('/notifications')
                .then(response => {
                    setNotifications(response.data.reverse())
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])

    React.useEffect(() => {
        document.body.classList.toggle('sidebar-icon-only', sidebarActiveOnPC)
    }, [sidebarActiveOnPC])

    React.useEffect(() => {
        document.getElementById("sidebar").classList.toggle('active', sidebarActiveOnMobile)
    }, [sidebarActiveOnMobile])
    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex justify-content-center">
                <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
                    <a className="navbar-brand brand-logo" href="#">
                        <img style={{ width: "100%" }} src="dashboard-logo.png" alt="logo" />
                    </a>
                    <a style={{ width: "50px" }} className="navbar-brand brand-logo-mini" href="#">
                        <img src="dashboard-logo.png" alt="logo" />
                    </a>
                    <button
                        onClick={() => setSidebarActiveOnPC(!sidebarActiveOnPC)}
                        className="navbar-toggler navbar-toggler align-self-center"
                        type="button"
                        data-toggle="minimize"
                    >
                        <span className="mdi mdi-sort-variant" />
                    </button>
                </div>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                {
                    localStorage.getItem('role') === "Holder" || window.location.pathname === "/dashboard" ?
                        null :
                        <ul className="navbar-nav mr-lg-4 w-100">
                            <li className="nav-item nav-search d-none d-lg-block w-100">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="search">
                                            <i className="mdi mdi-magnify" />
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search now"
                                        aria-label="search"
                                        aria-describedby="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                if (props.verificationRequests) {
                                                    props.setFilterData(
                                                        props.verificationRequests.filter(request => {
                                                            return request.holderEmail.includes(e.target.value)
                                                        }))
                                                }
                                                else if (props.credentials) {
                                                    props.setFilterData(
                                                        props.credentials.filter(credential => {
                                                            return credential.holderEmail.includes(e.target.value)
                                                        }))
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </li>
                        </ul>
                }
                <ul className="navbar-nav navbar-nav-right">
                    {
                        localStorage.getItem('role') === "Holder" ?
                            <li className="nav-item dropdown me-4">
                                <a
                                    className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center notification-dropdown"
                                    id="notificationDropdown"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                >
                                    <i className="mdi mdi-bell mx-0" />
                                    {notifications.length > 0 ? <span className="count" /> : null}
                                </a>
                                <div
                                    className="dropdown-menu dropdown-menu-right navbar-dropdown"
                                    aria-labelledby="notificationDropdown"
                                >
                                    <p className="mb-0 font-weight-normal float-left dropdown-header">
                                        Notifications
                                    </p>
                                    {
                                        notifications.map((notification, index) => {
                                            return (
                                                <>
                                                    <li style={{ cursor: "pointer" }} onClick={() => {
                                                        console.log(notification)
                                                        setNotification(notification)
                                                        setNotificationModal(true)
                                                    }} key={index}>
                                                        {
                                                            notification.notificationType === "Issue Credential" ?
                                                                <div className="dropdown-item">
                                                                    <div className="item-thumbnail">
                                                                        <div className="item-icon bg-info">
                                                                            <i style={{ marginTop: "3.5px" }} className="mdi mdi-account-card-details mx-0" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-content">
                                                                        <h6 className="font-weight-normal text-muted">New credential offer</h6>
                                                                        <p style={{ fontSize: "10px" }} className="font-weight-light small-text mb-0 text-muted">
                                                                            {
                                                                                new Date(notification.createdAt).toLocaleDateString('en-US', {
                                                                                    month: 'short',
                                                                                    day: 'numeric',
                                                                                    year: 'numeric'
                                                                                })
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="dropdown-item">
                                                                    <div className="item-thumbnail">
                                                                        <div className="item-icon bg-success">
                                                                            <i style={{ marginTop: "2.5px" }} className="mdi mdi-account-check mx-0" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-content">
                                                                        <h6 className="font-weight-normal text-muted">New verification request</h6>
                                                                        <p style={{ fontSize: "10px" }} className="font-weight-light small-text mb-0 text-muted">
                                                                            {
                                                                                new Date(notification.createdAt).toLocaleDateString('en-US', {
                                                                                    month: 'short',
                                                                                    day: 'numeric',
                                                                                    year: 'numeric'
                                                                                })
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </li>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </li>
                            : null
                    }
                    <li className="nav-item nav-profile dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            id="profileDropdown"
                        >
                            <i style={{ margin: "0px", fontSize: "25px" }} className="mdi mdi-account-circle" />
                            <span className="nav-profile-name">{localStorage.getItem("email")}</span>
                        </a>
                        <div
                            className="dropdown-menu dropdown-menu-right navbar-dropdown"
                            aria-labelledby="profileDropdown"
                        >
                            {/* <a className="dropdown-item">
                                <i className="mdi mdi-settings text-primary" />
                                Settings
                            </a> */}
                            <button onClick={() => {
                                localStorage.clear()
                                window.location.href = "/"
                            }} className="dropdown-item">
                                <i className="mdi mdi-logout text-primary" />
                                Logout
                            </button>
                        </div>
                    </li>
                </ul>
                <button
                    onClick={() => setSidebarActiveOnMobile(!sidebarActiveOnMobile)}
                    className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                    type="button"
                    data-toggle="offcanvas"
                >
                    <span className="mdi mdi-menu" />
                </button>
            </div>
            {
                notificationModal ? <NotificationModal closeModal={setNotificationModal} notification={notification} /> : null
            }
        </nav>
    )
}

export default Navbar
