import axios from 'axios';

const holderInstance = axios.create({ baseURL: 'https://qdid.io/api/holder/' });

const issuerInstance = axios.create({ baseURL: 'https://qdid.io/api/issuer/' });

const verifierInstance = axios.create({ baseURL: 'https://qdid.io/api/verifier/' });

holderInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("token");
        if (accessToken) {
            config.headers["Authorization"] = accessToken
            return config;
        }
        else {
            return Promise.reject("No token found");
        }
    },
    (error) => {
        Promise.reject(error);
    }
);

issuerInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("token");
        if (accessToken) {
            config.headers["Authorization"] = accessToken
            return config;
        }
        else {
            return Promise.reject("No token found");
        }
    },
    (error) => {
        Promise.reject(error);
    }
);

verifierInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("token");
        if (accessToken) {
            config.headers["Authorization"] = accessToken
            return config;
        }
        else {
            return Promise.reject("No token found");
        }
    },
    (error) => {
        Promise.reject(error);
    }
);

export { holderInstance, issuerInstance, verifierInstance };