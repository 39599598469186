import { toastSuccess, toastError } from './Alerts';
import { issuerInstance } from '../Axios/Auth'
import { loaderContext } from '../App'
import { useContext } from 'react'
import React from 'react';
import '../css/Modal.css';

const SchemaModal = (props) => {
    const { isActive, setIsActive } = useContext(loaderContext);

    const [name, setName] = React.useState('')
    const [response, setResponse] = React.useState('')
    const [attributes, setAttributes] = React.useState('')
    const [createSchemaState, setCreateSchemaState] = React.useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setCreateSchemaState(true)
        setIsActive(true)
    }

    React.useEffect(() => {
        async function createSchema() {
            const resp = await issuerInstance.post('/registerSchema', {
                name: name,
                schemaAttributes: attributes.split(',').map((item) => item.trim()),
            })
            setResponse(resp.data)
            setIsActive(false)
        }
        if (createSchemaState && isActive) {
            createSchema()
        }
    }, [isActive])

    React.useEffect(() => {
        if (response === '') return
        if (response.token) {
            localStorage.setItem('token', response.token)
            toastSuccess(response.message)
            props.closeModal(false)
        }
        else {
            toastError(response.error)
        }
        setResponse('')
    }, [response])

    return (
        <div style={{ zIndex: 99 }} className="modal__backdrop">
            <div className="modal__container">
                <div className="modal__close position-relative">
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => { props.closeModal(false) }}
                    ></button>
                </div>
                <h1>Create Schema</h1>
                <form className="form-horizontal form-material mx-2" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="col-md-12">Name</label>
                        <div className="col-md-12">
                            <input
                                type="text"
                                placeholder="Enter Your Schema Name"
                                className="form-control form-control-line"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="example-domain"
                            className="col-md-12"
                        >
                            Attributes List
                        </label>
                        <div className="col-md-12">
                            <input
                                type="text"
                                placeholder="Enter Your Attributes"
                                className="form-control form-control-line"
                                name="example-domain"
                                id="example-domain"
                                value={attributes}
                                onChange={(e) => { setAttributes(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-12">
                            <button
                                className="btn btn-primary text-white"
                                data-bs-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SchemaModal
