import React from 'react'
import "../css/Holder.css"
import { loaderContext } from '../App'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { holderInstance } from '../Axios/Auth'

const Holder = () => {
    const { isActive, setIsActive } = React.useContext(loaderContext);

    const [credentials, setCredentials] = React.useState([])
    const [credentialsState, setCredentialsState] = React.useState(false)
    const [selectedCredential, setSelectedCredential] = React.useState(null)

    React.useEffect(() => {
        if (credentialsState && isActive) {
            holderInstance.get('/').
                then(response => {
                    setCredentials(response.data)
                    setIsActive(false)
                })
                .catch(error => {
                    console.log(error)
                })
            setCredentialsState(false)
        }
    }, [isActive])

    React.useEffect(() => {
        if (localStorage.getItem('role') !== 'Holder' || localStorage.getItem('token') === null) {
            window.location.href = "/login"
        }
        setIsActive(true)
        setCredentialsState(true)
    }, [])
    return (
        <>
            <div className="container-scroller">
                {/* partial:partials/_navbar.html */}
                <Navbar />
                {/* partial */}
                <div className="container-fluid page-body-wrapper">
                    {/* partial:partials/_sidebar.html */}
                    <Sidebar />
                    {/* partial */}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div className="d-flex align-items-end flex-wrap">
                                            <div className="d-flex">
                                                <i className="mdi mdi-home text-muted hover-cursor mt-1" />
                                                <p className="text-muted mb-0 hover-cursor">
                                                    &nbsp;/&nbsp;Credentials
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    credentials.map((credential) => {
                                        return (
                                            <div className="col-md-4 mb-3">
                                                <div class="cred-card">
                                                    <div class="issue-wrap">
                                                        <div class="issuer">
                                                            <h3>Issued by</h3>
                                                            <p>{credential.issuerName}</p>
                                                        </div>
                                                        <div class="issuer-icon">
                                                            <span>
                                                                <i class="mdi mdi-account-card-details"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{ cursor: "pointer" }} onClick={() => setSelectedCredential(credential.credential)} class="issuer-details">See more details</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                selectedCredential !== null ?
                    <div style={{ zIndex: 99 }} className="modal__backdrop">
                        <div className="modal__container">
                            <div className="modal__close position-relative">
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => { setSelectedCredential(null) }}
                                ></button>
                            </div>
                            <h1>Details</h1>
                            <div>
                                {
                                    Object.keys(selectedCredential).map((key, index) => {
                                        console.log(key)
                                        return (
                                            <div class="mb-3">
                                                <strong><label for="exampleInputEmail1" class="form-label">{selectedCredential[key].name}</label></strong>
                                                <input class="form-control" value={selectedCredential[key].value} disabled />
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>

    )
}

export default Holder