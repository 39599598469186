import React from 'react'
import { loaderContext } from '../App'
import Navbar from '../components/Navbar'
import ReactPaginate from 'react-paginate'
import Sidebar from '../components/Sidebar'
import CSVModal from '../components/CSVModal'
import { issuerInstance } from '../Axios/Auth'
import SchemaModal from '../components/SchemaModal'
import CredentialModal from '../components/CredentialModal'

const Issuer = () => {
    const { isActive, setIsActive } = React.useContext(loaderContext);

    const [credentials, setCredentials] = React.useState([])
    const [schemaModal, setSchemaModal] = React.useState(false)
    const [credentialModal, setCredentialModal] = React.useState(false)
    const [csvModal, setCsvModal] = React.useState(false)
    const [filterData, setFilterData] = React.useState([])

    const [itemOffset, setItemOffset] = React.useState(0);
    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filterData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filterData.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filterData.length;
        setItemOffset(newOffset);
    };

    React.useEffect(() => {
        setIsActive(true)
    }, [])

    React.useEffect(() => {
        if (!schemaModal && isActive) {
            const getCredentials = async () => {
                try {
                    const response = await issuerInstance.get('/credentialsList')
                    setCredentials(response.data.reverse())
                    setFilterData(response.data.reverse())
                    setIsActive(false)
                } catch (error) {
                    console.log(error)
                }
            }
            getCredentials()
        }
    }, [isActive])

    React.useEffect(() => {
        if (localStorage.getItem('token') === null || localStorage.getItem('role') !== 'Issuer') {
            window.location.href = "/login"
        }
    }, [])

    return (
        <>
            {
                schemaModal ? <SchemaModal closeModal={setSchemaModal} /> : null
            }
            {
                credentialModal ? <CredentialModal closeModal={setCredentialModal} /> : null
            }
            {
                csvModal ? <CSVModal closeModal={setCsvModal} /> : null
            }
            <div className="container-scroller">
                {/* partial:partials/_navbar.html */}
                <Navbar credentials={credentials} setFilterData={setFilterData} />
                {/* partial */}
                <div className="container-fluid page-body-wrapper">
                    {/* partial:partials/_sidebar.html */}
                    <Sidebar />
                    {/* partial */}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div className="d-flex align-items-end flex-wrap">
                                            <div className="d-flex">
                                                <i className="mdi mdi-home text-muted hover-cursor mt-1" />
                                                <p className="text-muted mb-0 hover-cursor">
                                                    &nbsp;/&nbsp;Issue
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-end flex-wrap">
                                            <button
                                                onClick={() => setCsvModal(true)}
                                                data-tooltip="Upload CSV"
                                                type="button"
                                                className="tooltip1 btn btn-light bg-white btn-icon me-3 d-md-block"
                                            >
                                                <i className="mdi mdi-upload text-muted" />
                                            </button>
                                            <button
                                                onClick={() => setCredentialModal(true)}
                                                data-tooltip="Generate Credential"
                                                type="button"
                                                className="tooltip1 btn btn-light bg-white btn-icon me-3 mt-2 mt-xl-0"
                                            >
                                                <i className="mdi mdi-plus text-muted" />
                                            </button>
                                            <button onClick={() => setSchemaModal(true)} className="btn btn-primary mt-2 mt-xl-0">
                                                Generate Schema
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">Recent Requests</p>
                                            <div className="table-responsive">
                                                <table id="recent-purchases-listing" className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Created at</th>
                                                            <th>Schema Name</th>
                                                            <th>Properties</th>
                                                            <th>Holder Email</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            currentItems.map((credential) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{credential.createdAt}</td>
                                                                        <td>{credential.schemaName}</td>
                                                                        <td>{credential.properties}</td>
                                                                        <td>{credential.holderEmail}</td>
                                                                        <td><label className={
                                                                            credential.status === "pending" ? "text-warning" :
                                                                                credential.status === "issued" ? "text-success" : "text-danger"
                                                                        }>{credential.status}</label>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactPaginate
                                    nextLabel="&raquo;"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="&laquo;"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination justify-content-center mt-5"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Issuer
