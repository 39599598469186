import React from 'react'
import "./css/Tooltip.css"
import Home from './pages/Home'
import Login from './pages/Login'
import Issuer from './pages/Issuer'
import Holder from './pages/Holder'
import Register from './pages/Register'
import Verifier from './pages/Verifier'
import Dashboard from './pages/Dashboard'
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom'
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer } from 'react-toastify';

const loaderContext = React.createContext()

const App = () => {
  const [isActive, setIsActive] = React.useState(false)

  return (
    <div>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Loading your content...'
      >
        <loaderContext.Provider value={{ isActive, setIsActive }}>
          <Routes>
            <Route path="/" element={< Home />} />
            <Route path="/login" element={< Login />} />
            <Route path="/register" element={< Register />} />
            <Route path="/dashboard" element={< Dashboard />} />
            <Route path="/holder" element={<Holder />} />
            <Route path="/issuer" element={<Issuer />} />
            <Route path="/verifier" element={<Verifier />} />
          </Routes>
          <ToastContainer />
        </loaderContext.Provider>
      </LoadingOverlay>
    </div >
  )
}

export default App
export { loaderContext }