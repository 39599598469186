import React from 'react'
import '../css/Home.css'

const Home = () => {

    React.useEffect(() => {
        const toggleMenu = document.getElementById('toggleBtn');
        const mobileMenu = document.getElementById('mobile-nav');
        const closeMenu = document.getElementById('close-menu');
        const overlay = document.getElementById('overlay');

        toggleMenu.addEventListener('click', () => {
            mobileMenu.classList.add('active');
            overlay.classList.add('active');
        });

        closeMenu.addEventListener('click', () => {
            mobileMenu.classList.remove('active');
            overlay.classList.remove('active');
        });

        overlay.addEventListener('click', () => {
            mobileMenu.classList.remove('active');
            overlay.classList.remove('active');
        });
    }, [])

    return (
        <div style={{ background: "hsl(0, 0%, 0%)", }}>
            <model-viewer
                style={{
                    position: "fixed", top: "0", left: "0", width: "100%", height: "100%", zIndex: "1", opacity: "0.5"
                }}
                src="assets/models/glb/quantum.glb"
                poster="assets/poster/quantumPoster.png"
                shadow-intensity={1}
                ar=""
                ar-modes="webxr scene-viewer quick-look"
                camera-controls=""
                alt="Quantum Computer"
                autoPlay=""
            >
            </model-viewer>
            <div>
                <header id="header">
                    <div id="overlay" className="overlay" />
                    <nav style={{ position: "relative", zIndex: "2" }} className="nav container">
                        <div className="nav__logo">
                            <img style={{ height: "35px", marginBottom: "15px" }} src="did.png" alt="logo" />
                        </div>
                        {/* <ul className="nav__desktop-menu">
                            <li className="nav__item">
                                <a href="#" className="nav__link">Home</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" className="nav__link">About</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" className="nav__link">Services</a>
                            </li>
                            <li className="nav__item">
                                <a href="#" className="nav__link">Contact</a>
                            </li>
                        </ul> */}
                        <div className="nav__social">
                            <a href="#" className="nav__social-link">
                                <i className="fa-brands fa-facebook-f" />
                            </a>
                            <a href="#" className="nav__social-link">
                                <i className="fa-brands fa-github" />
                            </a>
                            <a href="#" className="nav__social-link">
                                <i className="fa-brands fa-twitter" />
                            </a>
                        </div>
                        <div id="toggleBtn" className="nav__toggle">
                            <span className="nav__toggle-line" />
                            <span className="nav__toggle-line" />
                            <span className="nav__toggle-line" />
                        </div>
                    </nav>
                    <div id="mobile-nav" className="mobile-nav">
                        <div className="mobile-nav__close" id="close-menu">
                            <i className="fa-solid fa-xmark" />
                        </div>
                        <ul className="mobile-nav__menu">
                            {/* <li className="mobile-nav__item">
                                <a href="#" className="mobile-nav__link">Home</a>
                            </li>
                            <li className="mobile-nav__item">
                                <a href="#" className="mobile-nav__link">About</a>
                            </li>
                            <li className="mobile-nav__item">
                                <a href="#" className="mobile-nav__link">Services</a>
                            </li>
                            <li className="mobile-nav__item">
                                <a href="#" className="mobile-nav__link">Contact</a>
                            </li> */}
                        </ul>
                        <div className="mobile-nav__social">
                            <a href="#" className="mobile-nav__social-link">
                                <i className="fa-brands fa-facebook-f" />
                            </a>
                            <a href="#" className="mobile-nav__social-link">
                                <i className="fa-brands fa-github" />
                            </a>
                            <a href="#" className="mobile-nav__social-link">
                                <i className="fa-brands fa-twitter" />
                            </a>
                        </div>
                    </div>
                </header>
                <section className="hero-section">
                    <h1 style={{ position: "relative", zIndex: "2" }} className="hero-section__heading"><span>Decentralized Quantum</span> <br /> Identity.</h1>
                    <p style={{ position: "relative", zIndex: "2" }} className="hero-section__subheading">Our technology represent an exciting development in the field of decentralized identities, offering a new level of security and privacy that is not possible with traditional decentralized IDs.</p>
                    <div style={{ position: "relative", zIndex: "2" }} className="hero-section__cta">
                        <a href="/login" className="button">Get Started</a>
                        <a href="https://www.youtube.com/watch?v=piYEtmG2Xr0" target="_blank" className="button button--grey">Eco System</a>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Home
