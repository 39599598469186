import { toastSuccess, toastError } from './Alerts'
import { issuerInstance } from '../Axios/Auth'
import React from 'react'

const CSVModal = (props) => {

    const [schemaName, setSchemaName] = React.useState('')
    const [csvFile, SetCsvFile] = React.useState([]);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()

            if (schemaName.length === "") {
                toastError("Please enter schema name")
                return
            }

            if (csvFile.length === 0) {
                toastError("Please select a file")
                return
            }

            const formData = new FormData();
            formData.append('schemaName', schemaName);
            formData.append('file', csvFile);

            const response = await issuerInstance.post('/batchIssue', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            );
            if (response.data.message) {
                toastSuccess(response.data.message)
                props.closeModal(false)
            }
            else {
                toastError(response.data.error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div style={{ zIndex: 99 }} className="modal__backdrop">
                <div className="modal__container">
                    <div className="modal__close position-relative">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => { props.closeModal(false) }}
                        ></button>
                    </div>
                    <h1>Upload CSV </h1>
                    <form className="form-horizontal form-material mx-2" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-12">Schema Name</label>
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    className="form-control form-control-line"
                                    name={schemaName}
                                    onChange={(e) => { setSchemaName(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-12">CSV File</label>
                            <div className="col-sm-12">
                                <input
                                    type={"file"}
                                    className="form-control form-control-line"
                                    name={"csv"}
                                    onChange={e => {
                                        SetCsvFile(e.target.files[0])
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-12">
                                <button
                                    className="btn btn-primary text-white"
                                    data-bs-dismiss="modal"
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CSVModal
