import React from 'react'
import '../css/Modal.css';
import { issuerInstance } from '../Axios/Auth'
import { toastSuccess, toastError } from './Alerts';

const CredentialModal = (props) => {

    const [schemas, setSchemas] = React.useState([])
    const [userEmail, setUserEmail] = React.useState('')
    const [selectedSchema, setSelectedSchema] = React.useState(null)
    const [credentialData, setCredentialData] = React.useState({})

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            const resp = await issuerInstance.post('/issueCredential', {
                schemaId: selectedSchema.id,
                credentialData: credentialData,
                holderEmail: userEmail
            })
            if (resp.data.error) {
                toastError(resp.data.error)
            }
            else {
                toastSuccess(resp.data.message)
                props.closeModal(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        const getSchemas = async () => {
            const response = await issuerInstance.post('/getSchemas', {
                email: localStorage.getItem('email')
            })
            setSchemas(response.data)
        }
        getSchemas()
    }, [])

    return (
        <div style={{ zIndex: 99 }} className="modal__backdrop">
            <div className="modal__container">
                <div className="modal__close position-relative">
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => { props.closeModal(false) }}
                    ></button>
                </div>
                <h1>Create Credential</h1>
                <form className="form-horizontal form-material mx-2" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="col-sm-12">Holder Email</label>
                        <div className="col-sm-12">
                            <input
                                type="text"
                                className="form-control form-control-line"
                                name={userEmail}
                                onChange={(e) => { setUserEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <select className="form-select" aria-label="Default select example" onChange={
                            (e) => {
                                const schema = schemas.find((schema) => schema.id === e.target.value)
                                setSelectedSchema(schema)
                            }
                        }>
                            <option value={null}>Select Schema</option>
                            {schemas.map((schema) => {
                                return (
                                    <option key={schema.id} value={schema.id}>{schema.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    {
                        selectedSchema && selectedSchema.attrNames.map((attrName, index) => {
                            return (
                                <div key={index} className="form-group">
                                    <label className="col-sm-12">{attrName}</label>
                                    <div className="col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control form-control-line"
                                            name={attrName}
                                            onChange={(e) => {
                                                setCredentialData({
                                                    ...credentialData,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="form-group">
                    </div>
                    <div className="form-group">
                        <div className="col-sm-12">
                            <button
                                className="btn btn-primary text-white"
                                data-bs-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CredentialModal
