import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        }
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Returns',
            data: [27, 35, 30, 40, 52, 48, 54, 46, 70],
            borderColor: [
                '#ff4747'
            ],
            borderWidth: 2,
            fill: false,
            pointBackgroundColor: "#fff"
        },
        {
            label: 'Sales',
            data: [29, 40, 37, 48, 64, 58, 70, 57, 80],
            borderColor: [
                '#4d83ff'
            ],
            borderWidth: 2,
            fill: false,
            pointBackgroundColor: "#fff"
        },
        {
            label: 'Loss',
            data: [90, 62, 80, 63, 72, 62, 40, 50, 38],
            borderColor: [
                '#ffc100'
            ],
            borderWidth: 2,
            fill: false,
            pointBackgroundColor: "#fff"
        }
    ]
};

export function LineChart(props) {
    const [data, setData] = React.useState({
        labels,
        datasets: [
            {
                label: 'Requests',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: [
                    '#4d83ff'
                ],
                borderWidth: 2,
                fill: false,
                pointBackgroundColor: "#fff"
            }
        ]
    });

    React.useEffect(() => {
        let monthWiseRequests = [];
        for (const month in labels) {
            if (props.data[labels[month]]) {
                monthWiseRequests.push(props.data[labels[month]]);
            }
            else {
                monthWiseRequests.push(0);
            }
        }
        setData({
            labels,
            datasets: [
                {
                    label: 'Requests',
                    data: monthWiseRequests,
                    borderColor: [
                        '#d9534f'
                    ],
                    borderWidth: 1.5,
                    fill: false,
                    pointBackgroundColor: "#fff"
                }
            ]
        });
    }, [props.data]);
    return <Line options={options} data={data} />;
}
