import React from 'react'
import { toastError } from '../components/Alerts'
import { Link, useNavigate } from 'react-router-dom'
import { issuerInstance, holderInstance, verifierInstance } from '../Axios/UnAuth'

const Login = () => {
    const navigate = useNavigate()

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [role, setRole] = React.useState('Issuer')

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            let resp = {}
            if (role === 'Issuer') {
                resp = await issuerInstance.post('/login', {
                    email: email,
                    password: password,
                })
            }
            else if (role === 'Holder') {
                resp = await holderInstance.post('/login', {
                    email: email,
                    password: password,
                })
            }
            else if (role === 'Verifier') {
                resp = await verifierInstance.post('/login', {
                    email: email,
                    password: password,
                })
            }
            else {
                toastError('Invalid Role')
            }
            if (resp.data.token) {
                localStorage.setItem('token', resp.data.token)
                localStorage.setItem('email', resp.data.email)
                localStorage.setItem('role', role)
                navigate('/dashboard')
            }
            else {
                toastError(resp.data.error)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src="dashboard-logo.png" style={{ width: "25%" }} alt="logo" />
                                </div>
                                <h4>Hello! let's get started</h4>
                                <h6 className="font-weight-light">Sign in to continue.</h6>
                                <form className="pt-3">
                                    <div className="form-group">
                                        <input
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            type="email"
                                            className="form-control form-control-lg"
                                            id="exampleInputEmail1"
                                            placeholder="Username"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            type="password"
                                            className="form-control form-control-lg"
                                            id="exampleInputPassword1"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Role</label>
                                        <select onChange={(e) => setRole(e.target.value)} value={role}
                                            class="form-select" aria-label="Default select example">
                                            <option value="Issuer">Issuer</option>
                                            <option value="Holder">Holder</option>
                                            <option value="Verifier">Verifier</option>
                                        </select>
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            onClick={handleSubmit}
                                        >
                                            SIGN IN
                                        </button>
                                    </div>
                                    {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label text-muted">
                                                <input type="checkbox" className="form-check-input" />
                                                Keep me signed in
                                            </label>
                                        </div>
                                        <a href="#" className="auth-link text-black">
                                            Forgot password?
                                        </a>
                                    </div> */}
                                    <div className="text-center mt-4 font-weight-light">
                                        Don't have an account?{" "}
                                        <Link to="/register" className="text-primary">
                                            Create
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* content-wrapper ends */}
            </div>
            {/* page-body-wrapper ends */}
        </div>
    )
}

export default Login
