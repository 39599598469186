import axios from 'axios';

const holderInstance = axios.create({
    baseURL: 'https://qdid.io/api/holder/'
});

const issuerInstance = axios.create({
    baseURL: 'https://qdid.io/api/issuer/'
});

const verifierInstance = axios.create({
    baseURL: 'https://qdid.io/api/verifier/'
});

export { holderInstance, issuerInstance, verifierInstance };