import VerificationRequestDetailsModal from '../components/VerificationRequestDetailsModal'
import PresentationModal from '../components/PresentationModal'
import { toastError } from '../components/Alerts'
import { verifierInstance } from '../Axios/Auth'
import Sidebar from '../components/Sidebar'
import ReactPaginate from 'react-paginate'
import Navbar from '../components/Navbar'
import { loaderContext } from '../App'
import React from 'react'

const Verifier = () => {
    const { isActive, setIsActive } = React.useContext(loaderContext);

    const [presentationModal, setPresentationModal] = React.useState(false)
    const [verificationRequests, setVerificationRequests] = React.useState([])
    const [selectedRequest, setSelectedRequest] = React.useState(null)
    const [filterData, setFilterData] = React.useState([])

    const [itemOffset, setItemOffset] = React.useState(0);
    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filterData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filterData.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filterData.length;
        setItemOffset(newOffset);
    };

    React.useEffect(() => {
        setIsActive(true)
    }, [])

    React.useEffect(() => {
        if (!presentationModal && isActive) {
            const getVerificationRequests = async () => {
                try {
                    const response = await verifierInstance.get('/')
                    setVerificationRequests(response.data.verificationRequestsData.reverse())
                    setFilterData(response.data.verificationRequestsData.reverse())
                    setIsActive(false)
                } catch (error) {
                    console.log(error)
                }
            }
            getVerificationRequests()
        }
    }, [isActive])

    React.useEffect(() => {
        if (localStorage.getItem('token') === null || localStorage.getItem('role') !== 'Verifier') {
            window.location.href = "/login"
        }
    }, [])

    return (
        <div>
            {
                presentationModal ? <PresentationModal closeModal={setPresentationModal} /> : null
            }
            <div className="container-scroller">
                {/* partial:partials/_navbar.html */}
                <Navbar verificationRequests={verificationRequests} setFilterData={setFilterData} />
                {/* partial */}
                <div className="container-fluid page-body-wrapper">
                    {/* partial:partials/_sidebar.html */}
                    <Sidebar />
                    {/* partial */}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div className="d-flex align-items-end flex-wrap">
                                            <div className="d-flex">
                                                <i className="mdi mdi-home text-muted hover-cursor mt-1" />
                                                <p className="text-muted mb-0 hover-cursor">
                                                    &nbsp;/&nbsp;Verify
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-end flex-wrap">
                                            <button onClick={() => setPresentationModal(true)} className="btn btn-primary mt-2 mt-xl-0">
                                                Generate Presentation
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">Recent Requests</p>
                                            <div className="table-responsive">
                                                <table id="recent-purchases-listing" className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Created at</th>
                                                            <th>Schema Name</th>
                                                            <th>Properties</th>
                                                            <th>Issuer Email</th>
                                                            <th>Holder Email</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            currentItems.map((verificationRequest) => {
                                                                return (
                                                                    <tr style={{ cursor: "pointer" }} onClick={() => {
                                                                        if (verificationRequest.status === "pending") {
                                                                            toastError("Verification Request is still pending")
                                                                        }
                                                                        else if (verificationRequest.status === "accepted") {
                                                                            setSelectedRequest(verificationRequest)
                                                                        }
                                                                        else {
                                                                            toastError("Verification Request is rejected")
                                                                        }
                                                                    }}>
                                                                        <td>{verificationRequest.createdAt}</td>
                                                                        <td>{verificationRequest.schemaName}</td>
                                                                        <td>{verificationRequest.properties}</td>
                                                                        <td>{verificationRequest.issuerEmail}</td>
                                                                        <td>{verificationRequest.holderEmail}</td>
                                                                        <td data-label="Status">
                                                                            <label className={
                                                                                verificationRequest.status === "pending" ? "text-warning" :
                                                                                    verificationRequest.status === "accepted" ? "text-success" : "text-danger"
                                                                            }>{verificationRequest.status}</label>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactPaginate
                                    nextLabel="&raquo;"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="&laquo;"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination justify-content-center mt-5"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                selectedRequest !== null ? <VerificationRequestDetailsModal request={selectedRequest} closeModal={setSelectedRequest} /> : null
            }
        </div>

    )
}

export default Verifier
