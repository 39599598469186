import { toastSuccess, toastError } from './Alerts'
import { holderInstance } from '../Axios/Auth'
import { loaderContext } from '../App'
import React from 'react'

const NotificationModal = (props) => {
    const { isActive, setIsActive } = React.useContext(loaderContext);
    const [decision, setDecision] = React.useState("")
    const [response, setResponse] = React.useState("")

    const acceptCredential = async (notificationId) => {
        try {
            const resp = await holderInstance.post('/acceptCredential', {
                notificationId: notificationId
            })
            setResponse(resp.data)
            setIsActive(false)
        } catch (error) {
            toastError(error)
        }
    }

    const rejectCredential = async (notificationId) => {
        try {
            const resp = await holderInstance.post('/rejectCredential', {
                notificationId: notificationId
            })
            setResponse(resp.data)
            setIsActive(false)
        } catch (error) {
            toastError(error)
        }
    }

    const acceptVerificationRequest = async (notificationId) => {
        try {
            const resp = await holderInstance.post('/acceptVerificationRequest', {
                notificationId: notificationId
            })
            setResponse(resp.data)
            setIsActive(false)
        } catch (error) {
            toastError(error)
        }
    }

    const rejectVerificationRequest = async (notificationId) => {
        try {
            const resp = await holderInstance.post('/rejectVerificationRequest', {
                notificationId: notificationId
            })
            setResponse(resp.data)
            setIsActive(false)
        } catch (error) {
            toastError(error)
        }
    }

    React.useEffect(() => {
        if (isActive) {
            if (decision === "Accept") {
                if (props.notification.notificationType === "Issue Credential") {
                    acceptCredential(props.notification._id)
                } else {
                    acceptVerificationRequest(props.notification._id)
                }
            } else if (decision === "Reject") {
                if (props.notification.notificationType === "Issue Credential") {
                    rejectCredential(props.notification._id)
                } else {
                    rejectVerificationRequest(props.notification._id)
                }
            }
        }
    }, [decision])

    React.useEffect(() => {
        if (response === "") return
        setResponse("")
        setDecision("")
        toastSuccess(response.message)
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }, [response])

    return (
        <div style={{ zIndex: 99 }} className="modal__backdrop">
            <div className="modal__container">
                <div className="modal__close position-relative">
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => { props.closeModal(false) }}
                    ></button>
                </div>
                <h1>
                    {
                        props.notification.notificationType === "Issue Credential" ? "Credential Request" : "Proof Request"
                    }
                </h1>
                <div>
                    {
                        Object.keys(props.notification.credentialData).map((key, index) => {
                            return (
                                <div class="mb-3">
                                    <strong><label for="exampleInputEmail1" class="form-label">{key}</label></strong>
                                    <input class="form-control" value={props.notification.credentialData[key]} disabled />
                                </div>
                            )
                        }
                        )
                    }
                    <div style={{ display: "flex", gap: "10px" }}>
                        <button onClick={() => { setDecision("Accept"); setIsActive(true) }} type="submit" style={{ color: "white" }} class="btn btn-primary">Accept</button>
                        <button onClick={() => { setDecision("Reject"); setIsActive(true) }} type="submit" style={{ color: "white" }} class="btn btn-danger">Reject</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationModal
