import { issuerInstance } from "../Axios/UnAuth"
import { verifierInstance } from "../Axios/Auth"
import { toastSuccess, toastError } from "./Alerts"
import React from 'react'

const PresentationModal = (props) => {
    const [issuers, setIssuers] = React.useState([])
    const [schemas, setSchemas] = React.useState([])
    const [userEmail, setUserEmail] = React.useState('')
    const [selectedIssuer, setSelectedIssuer] = React.useState(null)
    const [selectedSchema, setSelectedSchema] = React.useState(null)
    const [requestData, setRequestData] = React.useState([])

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            const resp = await verifierInstance.post('/createPresentationRequest', {
                schemaId: selectedSchema.id,
                holderEmail: userEmail,
                issuerEmail: selectedIssuer,
                requestData: requestData
            })
            if (resp.data.error) {
                toastError(resp.data.error)
            }
            else {
                toastSuccess(resp.data.message)
                props.closeModal(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        const getIssuers = async () => {
            const response = await issuerInstance.get('/issuerList')
            setIssuers(response.data)
        }
        getIssuers()
    }, [])

    React.useEffect(() => {
        const getSchemas = async () => {
            const response = await issuerInstance.post('/getSchemas', {
                email: selectedIssuer
            })
            setSchemas(response.data)
        }
        getSchemas()
    }, [selectedIssuer])

    return (
        <div>
            <div style={{ zIndex: 99 }} className="modal__backdrop">
                <div className="modal__container">
                    <div className="modal__close position-relative">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => { props.closeModal(false) }}
                        ></button>
                    </div>
                    <h1>Create Presentation</h1>
                    <form className="form-horizontal form-material mx-2" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-12">Holder Email</label>
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    className="form-control form-control-line"
                                    name={userEmail}
                                    onChange={(e) => { setUserEmail(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <select className="form-select" aria-label="Default select example" onChange={
                                (e) => {
                                    const issuer = issuers.find((issuer) => issuer === e.target.value)
                                    setSelectedIssuer(issuer)
                                }
                            }>
                                <option value={null}>Select Issuer</option>
                                {issuers.map((issuer) => {
                                    return (
                                        <option key={issuer} value={issuer}>{issuer}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <select className="form-select" aria-label="Default select example" onChange={
                                (e) => {
                                    const schema = schemas.find((schema) => schema.id === e.target.value)
                                    setSelectedSchema(schema)
                                }
                            }>
                                <option value={null}>Select Schema</option>
                                {schemas.map((schema) => {
                                    return (
                                        <option key={schema.id} value={schema.id}>{schema.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {
                            selectedSchema && selectedSchema.attrNames.map((attrName, index) => {
                                return (
                                    <div key={index} className="form-group">
                                        <div>
                                            <input onClick={() => {
                                                if (requestData.includes(attrName)) {
                                                    setRequestData(requestData.filter((attr) => attr !== attrName))
                                                }
                                                else {
                                                    setRequestData([...requestData, attrName])
                                                }
                                            }} class="form-check-input me-2" type="checkbox" value={attrName} id="flexCheckDefault" />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                {attrName}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="form-group">
                        </div>
                        <div className="form-group">
                            <div className="col-sm-12">
                                <button
                                    className="btn btn-primary text-white"
                                    data-bs-dismiss="modal"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PresentationModal
